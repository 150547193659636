import React, { Component } from 'react';
import {
  Drawer, Button, Modal, Card, Col, Row,
} from 'antd';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import { WechatOutlined, AlipayCircleOutlined } from '@ant-design/icons';
import { clearUserPayInfo } from '@/utils/index';
import MessageStore from '@/utils/message-store';
import styles from './style.module.less';
import Result from './result';
import TopUp from './topUp';

const propTypes = {
  userPayInfo: PropTypes.shape({
    id: PropTypes.string,
    money: PropTypes.number,
    orderType: PropTypes.number,
    open: PropTypes.bool,
  }),
  newUserPayInfo: PropTypes.shape({
    id: PropTypes.string,
    money: PropTypes.number,
    orderType: PropTypes.number,
    open: PropTypes.bool,
  }),
  setopenUserPay: PropTypes.func,
};

const defaultProps = {
  userPayInfo: {
    id: '',
    money: 0,
    orderType: 4,
  },
  newUserPayInfo: {
    id: '',
    money: 0,
    orderType: 4,
  },
  setopenUserPay: () => {},
};

class UserPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contraryPayisModalopen: false,
      userPayInfo: {},
    };
  }

  contraryPayHandleOk = () => {
    const { setopenUserPay } = this.props;
    this.setState({
      contraryPayisModalopen: false,
    });
    clearUserPayInfo();
    setopenUserPay(false);
  };

  contraryPayHandleCancel = () => {
    this.setState({
      contraryPayisModalopen: false,
    });
  };

  render() {
    const {
      orderStatus,
    } = MessageStore;
    const { userPayInfo } = this.state;
    const { newUserPayInfo } = this.props;
    const companyInfo = localStorage.getItem('companyInfo') ? JSON.parse(localStorage.getItem('companyInfo')) : {};
    const { id, money, orderType } = userPayInfo;
    const { contraryPayisModalopen } = this.state;
    const userId = JSON.parse(localStorage.getItem('userData'))?.userId;
    return (
      <div>
        <Drawer
          title="充值"
          width="450"
          open={newUserPayInfo.open}
          onClose={() => {
            const { setopenUserPay } = this.props;
            this.setState({
              userPayInfo: {},
            });
            clearUserPayInfo();
            setopenUserPay(false);
          }}
        >
          {!id && !orderStatus && (
          <TopUp
            onChangeId={(value) => {
              this.setState({
                userPayInfo: value,
              });
            }}
            companyInfo={companyInfo}
          />
          )}
          {id && !orderStatus && (
            <div className={styles.code}>
              <div className={styles.codeWrap}>
                <img
                  className={styles.codeavatar}
                  alt=""
                  src={
                    companyInfo.imgUrl
                    || 'https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/POrV/2021-07-06/6219c80023.png'
                  }
                />
                <div className={styles.codeCompanyname}>{companyInfo.name}</div>
                <div className={styles.codeMoney}>
                  ¥
                  {money / 100}
                </div>
                <div className={styles.codeQrcode}>
                  <QRCode
                    value={`https://crm.yunkecn.com/cashier-h5/#/pages/receiving-code/amount/index?id=${id}&shareUserId=9F11AC906E7843A58CE2F02EB1C24754&userId=${userId}&orderType=${orderType}&companyCode=${companyInfo.code}`}
                    size={145}
                    scale={4}
                    typenumber={2}
                  />
                </div>
                <div className={styles.codetips}>扫码付款</div>
                <div className={styles.codebottomtext}>
                  <WechatOutlined style={{ color: '#49C13C' }} size="20">
                    &#xe607;
                  </WechatOutlined>
                  <div className={styles.text}>
                    微信支付&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <AlipayCircleOutlined style={{ color: '#03A9F3' }} size="20">
                    &#xe602;
                  </AlipayCircleOutlined>
                  <div className={styles.text}>支付宝支付</div>
                </div>
                {/* {
                window.location.host.startsWith('test') && (
                  <div className={styles.ContraryPay}>
                    <div className={styles.ContraryPayName}>对公支付</div>
                    <Button
                      type="link"
                      className={styles.ContraryPayText}
                      onClick={() => {
                        this.setState({
                          contraryPayisModalopen: true,
                        });
                      }}
                    >
                      我要对公支付
                      {'>'}
                    </Button>
                  </div>
                )
              } */}
                <div className={styles.ContraryPay}>
                  <img
                    src="http://yunke-pcfile.oss-cn-beijing.aliyuncs.com/POrV/2022-07-14/aebe47e53a.png"
                    style={{ width: 15, height: 15 }}
                    alt=""
                  />
                  <div className={styles.ContraryPayName}>对公支付</div>
                  <Button
                    type="link"
                    className={styles.ContraryPayText}
                    onClick={() => {
                      this.setState({
                        contraryPayisModalopen: true,
                      });
                    }}
                  >
                    我要对公支付
                    {'>'}
                  </Button>
                </div>
                <p className={styles.ContraryPayTitle}>
                  获取对公支付详细信息，支付完成后我们会尽快确认到账户。
                </p>
              </div>
            </div>
          )}
          {orderStatus === 1 && <Result />}
        </Drawer>
        <Modal
          title="对公支付"
          open={contraryPayisModalopen}
          onOk={this.contraryPayHandleOk}
          width={800}
          onCancel={this.contraryPayHandleCancel}
          okText="已完成对公转账"
        >
          <div className="site-card-wrapper">
            <Row gutter={16}>
              <Col span={12}>
                <Card title="对公转账流程" bordered={false}>
                  <p>第一步:对公汇款</p>
                  <p>
                    公司名称:
                    <span style={{ marginLeft: 10 }}>上海颢丰科技有限公司</span>
                  </p>
                  <p>
                    开户行:
                    <span style={{ marginLeft: 10 }}>
                      工商银行长三角一体化示范区支行
                    </span>
                  </p>
                  <p>
                    账号:
                    <span style={{ marginLeft: 10 }}>1001742209300224440</span>
                  </p>
                  <p>
                    汇款金额:
                    <span style={{ marginLeft: 10 }}>
                      ¥
                      {money / 100}
                    </span>
                  </p>
                  <p>
                    汇款备注:
                    <span style={{ marginLeft: 10 }}>企业账户充值</span>
                  </p>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="对公转账流程" bordered={false}>
                  <p>第二步：升级账号</p>
                  <p>
                    转账完成后请点击
                    <span style={{ color: '#1890ff' }}>已完成对公转账</span>
                    ,我们收到汇款24小时内将会为您的账户进行充值。（周末、法定假日可能延迟）
                  </p>
                  <p>
                    汇款成功后，请您及时联系商务经理并提供企业串码和汇款凭证截图。我们的商务经理将通过宜搭系统为您提交充值申请，
                    正常会在1个工作日内将款项充值至您的企业账户。
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}
UserPay.propTypes = propTypes;
UserPay.defaultProps = defaultProps;
export default UserPay;
